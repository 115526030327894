import { WEB_HEADERS, API_USER_URL } from "@/core/config";
import { getUserLoginToken } from "@/core/utils/session";
import ApiService from "@/core/utils/api";

export default class UserApi {
  private apiService = new ApiService(API_USER_URL, {
    ...WEB_HEADERS,
    authorization: getUserLoginToken(),
  });

  async uploadFile (body: any) {
    const result = await this.apiService.upload("/file/upload", body);

    return {
      isSuccess: result.data.filename && result.data.link ? true : false,
      data: result.data as { link: string; filename: string; id: string },
    };
  }
}
