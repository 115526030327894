export const GET_USER_CONVERSATION_LIST =
  "conversation/getUserConversationList";

export const GET_MORE_USER_CONVERSATION_LIST =
  "conversation/getMoreUserConversationList";

export const SET_ACTIVE_CONVERSATION = "conversation/setActiveConversation";

export const UPDATE_ACTIVE_CONVERSATION =
  "conversation/updateActiveConversation";

export const UPDATE_CONVERSATION = "conversation/updateConversation";

export const READ_CONVERSATION_MESSAGE = "conversation/readConversationMessage";

export const MOVE_CONVERSATION_TO_TOP = "conversation/moveConversationToTop";

export const START_CONVERSATION = "conversation/startConversation";

export const SET_CONVERSATION_CHAT_STATUS =
  "conversation/setConversationChatStatus";

export const ADD_GROUP_USER = "conversation/addGroupUser";

export const REMOVE_GROUP_USER = "conversation/removeGroupUser";

export const REMOVE_CONVERSATION = "conversation/removeConversation";

export const SET_CONVERSATION = "conversation/setConversation";

export const UPDATE_GROUP_CONVERSATION = "conversation/updateGroupConversation";

export const EMIT_ACTIVE_CONVERSATION = "conversation/emitActiveConversation";
