import crypto from "@/core/utils/crypto";
import accountApi from "@/domain/api/account-api";

export default async (
  id: string,
  values: { oldPassword: string; newPassword: string }
) => {
  return await accountApi.changePassword(id, {
    oldPassword: crypto.sha256(values.oldPassword),
    newPassword: crypto.sha256(values.newPassword),
  });
};
