import { IMessage } from "@/domain/interfaces/message-interfaces";

export interface MessageState {
  loading: boolean;
  isSuccess: boolean;
  error: string;

  fetching: boolean;
  moreFetching: boolean;
  scrollToMessage: string;
  data: {
    items: Array<IMessage>;
    total: number;
    limit: number;
    page: number;
    groupByDate: Array<{ date: string; messageInDay: Array<IMessage> }>;
  };
  totalUnreadCount: number;
  forwardMessage?: IMessage;
}

const state: MessageState = {
  loading: false,
  isSuccess: false,
  error: "",

  fetching: false,
  moreFetching: false,
  data: {
    items: [],
    groupByDate: [],
    page: 1,
    total: 0,
    limit: 10,
  },
  totalUnreadCount: 0,
  scrollToMessage: "",
};

export default state;
