
import color from "@/core/utils/color";
import { defineComponent } from "vue";
import { VueSpinner, VueSpinnerDots, VueSpinnerPuff, VueSpinnerTail } from "vue3-spinners";

export default defineComponent({
  props: {
    size: { type: Number, default: 50 },
    type: {
      type: String,
      default: "default",
      validator (value: string) {
        return ["default", "dots", "puff", "tail"].includes(value);
      },
    },
  },
  components: {
    VueSpinnerPuff,
    VueSpinnerDots,
    VueSpinnerTail,
    VueSpinner,
  },
  setup () {
    return { color };
  },
});
