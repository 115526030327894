import { IConversation } from "@/domain/interfaces/conversation-interfaces";

export interface ConversationState {
  loading: boolean;
  isSuccess: boolean;
  error: string;

  fetching: boolean;
  moreFetching: boolean;

  data: {
    items: Array<IConversation>;
    total: number;
    page: number;
    limit: number;
  };
  activeConversation?: IConversation;
}

const state: ConversationState = {
  loading: false,
  isSuccess: false,
  error: "",

  fetching: false,
  moreFetching: false,
  data: {
    items: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  activeConversation: undefined,
};

export default state;
