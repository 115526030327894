import { WEB_HEADERS, API_USER_URL } from "@/core/config";
import { API_SUCCESS } from "@/core/utils/api/error";
import { getUserLoginToken } from "@/core/utils/session";
import ApiService from "@/core/utils/api";

export default class MessageApi {
  private apiService = new ApiService(API_USER_URL, {
    ...WEB_HEADERS,
    authorization: getUserLoginToken(),
  });

  async removeMessage (id: string) {
    const result = await this.apiService.remove(`/message/${id}`);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        lastMessage: result.data.lastMessage,
        conversation: result.data.conversation,
      },
    };
  }

  async getConversationMessage (id: string, query: { page: number }) {
    const result = await this.apiService.get(
      `/message/conversation/${id}`,
      query
    );

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }
}
