import { IAccountLogin } from "@/domain/interfaces/account-interfaces";
import { ILogin } from "@/domain/interfaces/user-interfaces";

export function getLoginMode () {
  try {
    const session = sessionStorage.getItem("loginUser");

    if (session) {
      const data = JSON.parse(session) as ILogin;

      if (data && data.mode) return data.mode || "";
    }

    return "";
  } catch (error) {
    return "";
  }
}

export function getLoginUser () {
  try {
    const session = sessionStorage.getItem("loginUser");

    if (session) {
      const data = JSON.parse(session) as ILogin;

      if (data && data.user) return data.user;
    }
  } catch (error) {
    return null;
  }
}

export function getUserLoginToken () {
  try {
    const session = sessionStorage.getItem("loginUser");

    if (session) {
      const data = JSON.parse(session) as ILogin;

      if (data && data.token) return data.token;
    }
  } catch (error) {
    return "";
  }
}

export function setLoginUser (data: ILogin) {
  sessionStorage.setItem("loginUser", JSON.stringify(data));
}

export function removeLoginUser () {
  sessionStorage.removeItem("loginUser");
}

// Account
export function getLoginAccount () {
  try {
    const session = sessionStorage.getItem("loginAccount");

    if (session) {
      const data = JSON.parse(session) as ILogin;

      if (data && data.user) return data.user;
    }
  } catch (error) {
    return null;
  }
}

export function getAccountLoginToken () {
  try {
    const session = sessionStorage.getItem("loginAccount");

    if (session) {
      const data = JSON.parse(session) as ILogin;

      if (data && data.token) return data.token;
    }
  } catch (error) {
    return "";
  }
}

export function setLoginAccount (data: IAccountLogin) {
  sessionStorage.setItem("loginAccount", JSON.stringify(data));
}

export function removeLoginAccount () {
  sessionStorage.removeItem("loginAccount");
}
