import UserApi from "@/domain/api/user-api";

export default async (values: {
  product: string;
  token: string;
  org: string;
  branchid: string;
  employeeid?: string;
  accessToken: string;
  phone?: string;
  cusid?: string;
  cusavatar?: string;
}) => {
  if (
    !values.product ||
    !values.token ||
    !values.branchid ||
    (!values.employeeid && !values.phone) ||
    !values.org
  ) 
    return {
      isSuccess: false,
      message: "Đăng nhập không thành công!",
      data: {
        token: undefined,
        user: undefined,
        activeConversation: undefined,
        mode: undefined,
      },
    };
  

  return await new UserApi().customerCareLogin({
    product: values.product,
    token: values.token,
    username: values.employeeid || values.phone || "",
    accessToken: values.accessToken,
    takeCareGroup: values.branchid,
    source: values.org,
    chatUsername: values.cusid,
    chatUserAvatar: values.cusavatar,
  });
};
