import { IConversation } from "@/domain/interfaces/conversation-interfaces";
import { ILastMessage } from "@/domain/interfaces/message-interfaces";
import { MutationTree } from "vuex";
import { ConversationState } from "./state";

const mutations: MutationTree<ConversationState> = {
  GET_CONVERSATION_FETCHING (state) {
    state.fetching = true;
    state.data.items = [];
    state.data.total = 0;
  },
  GET_CONVERSATION_SUCCESS (
    state,
    data: { items: Array<IConversation>; total: number; limit: number }
  ) {
    state.fetching = false;
    state.data.items = data.items;
    state.data.total = data.total;
    state.data.limit = data.limit;
    state.data.page = 1;
  },
  GET_CONVERSATION_ERROR (state) {
    state.fetching = false;
    state.data.items = [];
    state.data.total = 0;
  },
  GET_MORE_CONVERSATION_SUCCESS (
    state,
    data: { items: Array<IConversation>; total: number; limit: number }
  ) {
    state.moreFetching = false;
    state.data.total = data.total;
    state.data.limit = data.limit;
    state.data.items = state.data.items.concat(data.items);
    state.data.page = state.data.page + 1;
  },
  GET_MORE_CONVERSATION_ERROR (state) {
    state.moreFetching = false;
  },
  SET_ACTIVE_CONVERSATION (state, activeConversation: IConversation) {
    state.activeConversation = activeConversation;
  },
  SET_CONVERSATION (state, conversation: IConversation) {
    state.activeConversation = conversation;
    const index = state.data.items.findIndex(
      (item) => item._id === conversation._id
    );

    if (index !== -1) 
      state.data.items[index] = conversation;
    
  },
  UPDATE_ACTIVE_CONVERSATION (
    state,
    data: {
      lastMessage?: ILastMessage;
      unreadCount?: number;
      name?: string;
      avatar?: string;
      description?: string;
    }
  ) {
    if (!state.activeConversation) return;

    if (data.lastMessage)
      state.activeConversation = {
        ...state.activeConversation,
        lastMessage: data.lastMessage,
      };

    if (data.unreadCount)
      state.activeConversation = {
        ...state.activeConversation,
        unreadCount: data.unreadCount,
      };

    if (data.name) 
      state.activeConversation = {
        ...state.activeConversation,
        name: data.name,
      };
    
    if (data.description) 
      state.activeConversation = {
        ...state.activeConversation,
        description: data.description,
      };
    
    if (data.avatar) 
      state.activeConversation = {
        ...state.activeConversation,
        avatar: data.avatar,
      };
    
  },
  UPDATE_CONVERSATION (
    state,
    data: {
      conversation: string;
      lastMessage?: ILastMessage;
      unreadCount?: number;
      name?: string;
      avatar?: string;
      description?: string;
    }
  ) {
    const index = state.data.items.findIndex(
      (item) => item._id === data.conversation
    );
    const item = state.data.items.find(
      (item) => item._id === data.conversation
    );

    if (item) {
      const updateData = { ...item };

      if (data.lastMessage) 
        updateData.lastMessage = data.lastMessage;
      
      if (data.unreadCount !== undefined && data.unreadCount !== null) 
        updateData.unreadCount = data.unreadCount;
      
      if (data.name) 
        updateData.name = data.name;
      
      if (data.avatar) 
        updateData.avatar = data.avatar;
      
      if (data.description) 
        updateData.description = data.description;
      

      state.data.items.splice(index, 1, updateData);
    }
  },
  MOVE_CONVERSATION_TO_TOP (state, conversation: IConversation) {
    const conversationIndex = state.data.items.findIndex(
      (item) => item._id === conversation._id
    );

    if (conversationIndex === 0) return;

    if (conversationIndex > 0) 
      state.data.items.splice(conversationIndex, 1);
    

    state.data.items.unshift(conversation);
  },
  SET_CONVERSATION_CHAT_STATUS (
    state,
    data: { id: string; status: string; time: Date }
  ) {
    const index = state.data.items.findIndex(
      (item) => item.receiveUser.id === data.id
    );

    if (index === -1) return;

    state.data.items[index].receiveUser.chatStatus.status = data.status;
    state.data.items[index].receiveUser.chatStatus.time = data.time;
  },
  REMOVE_CONVERSATION (state, conversation: string) {
    if (state.activeConversation?._id === conversation) 
      state.activeConversation = undefined;
    
    state.data.items = state.data.items.filter(
      (item) => item._id !== conversation
    );
    state.data.total--;
  },
};

export default mutations;
