import {
  getLoginMode,
  removeLoginUser,
  setLoginUser
} from "@/core/utils/session";
import { groupUserByLetter } from "@/domain/helpers/user-helper";
import { ILogin, IUser } from "@/domain/interfaces/user-interfaces";
import socketConnect from "@/infrastructure/socket";
import { MutationTree } from "vuex";
import { UserState, emptyUser } from "./state";

const mutations: MutationTree<UserState> = {
  SET_LOADING (state) {
    state.loading = true;
    state.isSuccess = false;
  },
  SET_CURRENT_LOGIN_USER (state, loginUser: IUser) {
    const mode = getLoginMode();
    state.loginUser = loginUser;
    state.mode = mode;
    socketConnect();
  },
  USER_LOGIN_SUCCESS (state, data: ILogin) {
    setLoginUser(data);
    state.loginUser = data.user;
    state.mode = data.mode;
    state.isSuccess = true;
    state.loading = false;
    socketConnect();
  },
  USER_LOGIN_ERROR (state, error) {
    state.loading = false;
    state.isSuccess = false;
    state.error = error;
    state.loginUser = emptyUser;
  },
  GET_FRIEND_FETCHING (state, status: boolean) {
    state.friend.fetching = status;
    state.friend.page = 1;
  },
  GET_FRIEND_SUCCESS (state, data) {
    state.friend.fetching = false;
    state.friend.items = data.items;
    state.friend.total = data.total;
    state.friend.groupByLetter = groupUserByLetter(data.items);
  },
  GET_MORE_FRIEND_FETCHING (state, status) {
    state.friend.moreFetching = status;
  },
  GET_MORE_FRIEND_SUCCESS (state, data) {
    state.friend.moreFetching = false;
    state.friend.total = data.total;
    state.friend.items = state.friend.items.concat(data.items);
    state.friend.groupByLetter = groupUserByLetter(state.friend.items);
    state.friend.page = state.friend.page + 1;
  },
  SET_FRIEND_CHAT_STATUS (
    state,
    data: { id: string; status: string; time: Date }
  ) {
    const index = state.friend.items.findIndex((item) => item._id === data.id);

    if (index === -1) return;

    state.friend.items[index].chatStatus.status = data.status;
    state.friend.items[index].chatStatus.time = data.time;
  },
  USER_LOGOUT_SUCCESS (state) {
    state.loginUser = emptyUser;
    removeLoginUser();
  },
  REGISTER_USER_SUCCESS (state) {
    state.isSuccess = true;
    state.loading = false;
    state.error = "";
  },
  REGISTER_USER_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  RESET_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  RESET_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  CHANGE_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  CHANGE_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  FORGOT_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  FORGOT_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
};

export default mutations;
