export const CUSTOMER_CARE_LOGIN = "user/customerCareLogin";

export const CUSTOMER_LOGIN = "user/customerLogin";

export const USER_LOGIN = "user/userLogin";

export const SET_CURRENT_LOGIN_USER = "user/setCurrentLoginUser";

export const USER_GET_FRIENDS = "user/getFriends";

export const USER_GET_MORE_FRIENDS = "user/getMoreFriends";

export const SET_FRIEND_CHAT_STATUS = "user/setFriendChatStatus";

export const CUSTOMER_CARE_GET_UNREAD_COUNT = "user/customerCareUnreadCount";
