import { ADMIN_HEADERS, API_HOST } from "@/core/config";
import { API_SUCCESS } from "@/core/utils/api/error";
import { getAccountLoginToken } from "@/core/utils/session";
import ApiService from "@/core/utils/api";

class AccountApi {
  private apiService = new ApiService(API_HOST, {
    ...ADMIN_HEADERS,
    authorization: getAccountLoginToken(),
  });

  async getAccountListPagination (query: object) {
    const result = await this.apiService.get("/account/pagination", query);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit),
      },
    };
  }

  async getAccountList (query: object) {
    const result = await this.apiService.get("/account", query);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
      },
    };
  }

  async createAccount (body: object) {
    const result = await this.apiService.post("/account", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async updateAccount (id: string, body: object) {
    const result = await this.apiService.put(`/account/${id}`, body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async updateDeviceInfo (id: string) {
    const result = await this.apiService.put(`/account/${id}/device-info`, {});

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async removeAccount (id: string) {
    const result = await this.apiService.remove(`/account/${id}`);

    return {
      ...{ isSuccess: result.code === API_SUCCESS.code },
      ...result,
    };
  }

  async login (body: object) {
    const result = await this.apiService.post("/account/login", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: { token: result.data.token, account: result.data.account },
    };
  }

  async logout (id: string) {
    const result = await this.apiService.put(`/account/${id}/logout`, {});

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async changePassword (id: string, body: object) {
    const result = await this.apiService.put(
      `/account/${id}/change-password`,
      body
    );

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async forgotPassword (body: object) {
    const result = await this.apiService.post("/account/forgot-password", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async resetPassword (body: object) {
    const result = await this.apiService.post("/account/reset-password", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async uploadAvatar (body: object) {
    const result = await this.apiService.upload("/account/upload/avatar", body);

    return {
      isSuccess: result.data.id && result.data.url ? true : false,
      data: result.data,
    };
  }
}

export default new AccountApi();
