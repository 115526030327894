import { API_USER_URL, WEB_HEADERS } from "@/core/config";
import ApiService from "@/core/utils/api";
import { API_SUCCESS } from "@/core/utils/api/error";
import { getUserLoginToken } from "@/core/utils/session";
import { IUser } from "../interfaces/user-interfaces";

export default class UserApi {
  private apiService = new ApiService(API_USER_URL, {
    ...WEB_HEADERS,
    authorization: getUserLoginToken(),
  });

  async getUserDetail (query: object) {
    const result = await this.apiService.get("/user/detail", query);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data as IUser,
    };
  }
  async updateDeviceInfo (id: string) {
    const result = await this.apiService.put(`/user/${id}/device-info`, {});

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async login (body: object) {
    const result = await this.apiService.post("/user/login", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: { token: result.data.token, user: result.data.user },
    };
  }

  async customerCareLogin (body: {
    product: string;
    token: string;
    username: string;
    accessToken: string;
    takeCareGroup: string;
    source: string;
    chatUserAvatar?: string;
    chatUsername?: string;
  }) {
    const result = await this.apiService.post(
      "/user/customer-care-login",
      body
    );

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        token: result.data.token,
        user: result.data.user,
        activeConversation: result.data.activeConversation,
        mode: result.data.mode,
      },
    };
  }

  async customerLogin (body: {
    product: string;
    token: string;
    username: string;
    accessToken: string;
    takeCareGroup: string;
    source: string;
    deviceInfo: { appVersion: string };
  }) {
    const result = await this.apiService.post("/user/customer-login", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        token: result.data.token,
        user: result.data.user,
        activeConversation: result.data.activeConversation,
        mode: result.data.mode,
      },
    };
  }

  async userLogin (body: {
    product: string;
    token: string;
    username: string;
    accessToken: string;
    conversationId?: string;
  }) {
    const result = await this.apiService.post("/user/user-login", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        token: result.data.token,
        user: result.data.user,
        activeConversation: result.data.activeConversation,
        mode: result.data.mode,
      },
    };
  }

  async getFriendList (query: {
    page: number;
    search?: string;
    conversation?: string;
  }) {
    const result = await this.apiService.get("/user/friends", query);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }

  async logout (id: string) {
    const result = await this.apiService.put(`/user/${id}/logout`, {});

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async changePassword (id: string, body: object) {
    const result = await this.apiService.put(
      `/user/${id}/change-password`,
      body
    );

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async forgotPassword (body: object) {
    const result = await this.apiService.post("/user/forgot-password", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async resetPassword (body: object) {
    const result = await this.apiService.post("/user/reset-password", body);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data,
    };
  }

  async uploadAvatar (body: object) {
    const result = await this.apiService.upload("/user/upload/avatar", body);

    return {
      isSuccess: result.data.id && result.data.url ? true : false,
      data: result.data,
    };
  }
}
