export const MESSAGE_TYPE = {
  text: "text",
  image: "image",
  video: "video",
  audio: "audio",
  href: "href",
  file: "file",
};

export const MESSAGE_STATUS = {
  sent: "sent",
  remove: "remove",
  sending: "sending",
};

export const IMAGE_UPLOAD_FILE_TYPE = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/bmp",
];

export const VIDEO_UPLOAD_FILE_TYPE = ["video/mp4"];

export const IMAGE_FILE_TYPE = ["jpg", "gif", "png", "jpeg", "bmp"];

export const VIDEO_FILE_TYPE = ["mp4"];
