import { Module } from "vuex";
import { RootState } from "../../state";

import state, { UserState } from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const account: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default account;
