import { GetterTree } from "vuex";
import { RootState } from "../../state/index";
import { UserState } from "./state";

export const getters: GetterTree<UserState, RootState> = {
  loginUser: (state) => {
    return state.loginUser;
  },
  isUserLogin: (state) => {
    return state.loginUser.id !== "";
  },
  isMoreFriend: (state) => {
    const count = state.friend.items.length;
    const total = state.friend.total;

    if (total - count > 0) return true;

    return false;
  },
};

export default getters;
