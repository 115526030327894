export function isImage (type: string) {
  if (type.includes("image")) return true;

  return false;
}

export function isVideo (type: string) {
  if (type.includes("video")) return true;

  return false;
}

export function isAudio (type: string) {
  if (type.includes("audio")) return true;

  return false;
}
