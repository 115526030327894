import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "../../state";

export interface AlertState {
  type: string;
  message: string;
}

const state: AlertState = {
  type: "",
  message: "",
};

const mutations: MutationTree<AlertState> = {
  success (state, message) {
    state.type = "success";
    state.message = message;
  },
  error (state, message) {
    state.type = "error";
    state.message = message;
  },
  clear (state) {
    state.type = "";
    state.message = "";
  },
};

const actions: ActionTree<AlertState, RootState> = {
  success ({ commit }, message) {
    commit("success", message);
  },
  error ({ commit }, message) {
    commit("error", message);
  },
  clear ({ commit }) {
    commit("clear");
  },
};

const getters: GetterTree<AlertState, RootState> = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
