import { createStore, createLogger, Store } from "vuex";
import { RootState } from "./state";
import { InjectionKey } from "vue";
import modules from "./modules";

const debug = process.env.NODE_ENV !== "production";

export const key: InjectionKey<Store<RootState>> = Symbol();

export default createStore<RootState>({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions:  {},
  modules,
  strict: process.env.NODE_ENV !== "production",
  plugins: debug ? [createLogger()] : [],
});
