import { isLink } from "@/core/utils/common";
import { dateToString } from "@/core/utils/date";
import { isAudio, isImage, isVideo } from "@/core/utils/file";
import { IMessage } from "@/domain/interfaces/message-interfaces";
import _ from "lodash";
import {
  IMAGE_FILE_TYPE,
  MESSAGE_TYPE,
  VIDEO_FILE_TYPE
} from "../constant/message-constant";

export function groupMessageByDate (messages: Array<IMessage>) {
  return _(messages)
    .groupBy((mess) => dateToString(mess.sentAt))
    .map((messageInDay, date) => ({
      date,
      messageInDay: messageInDay,
    }))
    .valueOf();
}

export function getMessageTypeByContent (content: string) {
  if (isLink(content)) {
    const url = new URL(content);
    const extension = url.pathname.split(".")[1];

    if (IMAGE_FILE_TYPE.includes(extension)) return MESSAGE_TYPE.image;

    if (VIDEO_FILE_TYPE.includes(extension)) return MESSAGE_TYPE.video;

    return MESSAGE_TYPE.href;
  }

  return MESSAGE_TYPE.text;
}

export function getMessageTypeByFile (fileType: string) {
  if (isImage(fileType)) return MESSAGE_TYPE.image;

  if (isVideo(fileType)) return MESSAGE_TYPE.video;

  if (isAudio(fileType)) return MESSAGE_TYPE.audio;

  return MESSAGE_TYPE.file;
}
