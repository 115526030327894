export const handleCopy = (text?: string) => {
  if (!text) return;

  navigator.clipboard.writeText(text);
};

export function isLink (text: string) {
  if (!text) return false;
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  return text.match(urlRegex) ? true : false;
}
