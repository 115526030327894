import { WEB_HEADERS, API_USER_URL } from "@/core/config";
import { API_SUCCESS } from "@/core/utils/api/error";
import { getUserLoginToken } from "@/core/utils/session";
import ApiService from "@/core/utils/api";

export default class ConversationApi {
  private apiService = new ApiService(API_USER_URL, {
    ...WEB_HEADERS,
    authorization: getUserLoginToken(),
  });

  async getConversationList (query: {
    user?: string;
    takeCareGroup?: string;
    search?: string;
    page: number;
  }) {
    const result = await this.apiService.get("/conversation/user", query);

    return {
      isSuccess: result.code === API_SUCCESS.code,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
      },
    };
  }
}
