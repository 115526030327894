import { IConversation } from "@/domain/interfaces/conversation-interfaces";
import getUserConversationsUseCase from "@/domain/usecases/conversation/get-user-conversation-list";

import { ALERT_ERROR } from "@/domain/constant/store/actions/alert-actions";
import { READ_CONVERSATION_MESSAGE } from "@/domain/constant/store/actions/conversation-actions";
import { GET_CONVERSATION_MESSAGE } from "@/domain/constant/store/actions/message-actions";
import { USER_MODE } from "@/domain/constant/user-constant";
import { ILastMessage, IMessage } from "@/domain/interfaces/message-interfaces";
import {
  ACTION,
  emitActiveConversation,
  emitAddGroupUser,
  emitReadMessage,
  emitRemoveGroupUser,
  emitStartChat,
  emitUpdateGroupInfo
} from "@/infrastructure/socket";
import { RootState } from "@/infrastructure/store/state";
import { ActionTree } from "vuex";
import { ConversationState } from "./state";

export const actions: ActionTree<ConversationState, RootState> = {
  async getUserConversationList (
    { commit, dispatch, rootState },
    value: { search?: string }
  ) {
    commit("GET_CONVERSATION_FETCHING");
    const { loginUser, mode } = rootState.user;

    const id =
      mode === USER_MODE.customerCare
        ? loginUser.takeCareGroup?.id
        : loginUser.id;

    const result = await getUserConversationsUseCase({
      id,
      mode,
      search: value?.search,
      page: 1,
    });

    if (result.isSuccess)
      return commit("GET_CONVERSATION_SUCCESS", result.data);

    commit("GET_CONVERSATION_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async getMoreUserConversationList (
    { commit, dispatch, rootState, state },
    value: { search?: string }
  ) {
    if (state.fetching) return;

    const { loginUser, mode } = rootState.user;

    const id =
      mode === USER_MODE.customerCare
        ? loginUser.takeCareGroup?.id
        : loginUser.id;

    const result = await getUserConversationsUseCase({
      id,
      mode,
      search: value.search,
      page: state.data.page + 1,
    });

    if (result.isSuccess)
      return commit("GET_MORE_CONVERSATION_SUCCESS", result.data);

    commit("GET_MORE_CONVERSATION_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async setActiveConversation (
    { commit, dispatch },
    data: { conversation: IConversation }
  ) {
    if (!data.conversation?._id) {
      commit("SET_ACTIVE_CONVERSATION", undefined);

      return;
    }

    commit(
      "SET_ACTIVE_CONVERSATION",
      JSON.parse(JSON.stringify(data.conversation))
    );

    commit("UPDATE_CONVERSATION", {
      conversation: data.conversation._id,
      unreadCount: 0,
    });

    if (data.conversation.unreadCount > 0)
      dispatch(
        READ_CONVERSATION_MESSAGE,
        {
          conversationId: data.conversation._id,
          messageId: data.conversation.lastMessage._id,
        },
        { root: true }
      );

    dispatch(
      GET_CONVERSATION_MESSAGE,
      { id: data.conversation._id },
      { root: true }
    );
  },
  async updateActiveConversation ({ commit }, data) {
    commit("UPDATE_ACTIVE_CONVERSATION", data);
  },
  async updateConversation (
    { commit },
    data: {
      conversation: string;
      lastMessage?: ILastMessage;
      unreadCount?: number;
    }
  ) {
    commit("UPDATE_CONVERSATION", data);
  },
  async setConversation ({ commit }, conversation: string) {
    commit("SET_CONVERSATION", conversation);
  },
  async readConversationMessage (
    { rootState },
    values: { conversationId: string; messageId: string }
  ) {
    const detailId =
      rootState.user.mode === USER_MODE.customerCare
        ? rootState.conversation.activeConversation?.takeCareGroup || ""
        : rootState.user.loginUser.id;

    emitReadMessage({
      detailId,
      conversationId: values.conversationId,
      messageId: values.messageId,
    });
  },
  async moveConversationToTop ({ commit }, conversation) {
    commit("MOVE_CONVERSATION_TO_TOP", conversation);
  },
  async startConversation (
    context,
    data: {
      users: Array<string>;
      avatar: string;
      name: string;
      description: string;
    }
  ) {
    emitStartChat({
      users: data.users,
      avatar: data.avatar,
      name: data.name,
      description: data.description,
    });
  },
  async setConversationChatStatus ({ commit }, data) {
    commit("SET_CONVERSATION_CHAT_STATUS", data);
  },
  async addGroupUser (context, data) {
    emitAddGroupUser(data);
  },
  async removeGroupUser (context, data) {
    emitRemoveGroupUser(data);
  },
  async removeConversation ({ commit }, conversation: string) {
    commit("REMOVE_CONVERSATION", conversation);
  },
  async updateGroupConversation (
    { commit },
    data: {
      conversation: string;
      action: string;
      name?: string;
      avatar?: string;
      description?: string;
    }
  ) {
    commit("UPDATE_ACTIVE_CONVERSATION", data);
    commit("UPDATE_CONVERSATION", data);

    if (data.action === ACTION.emit) emitUpdateGroupInfo(data);
  },
  async emitActiveConversation (
    context,
    data: {
      conversation: string;
      user: string;
      forwardMessage?: IMessage;
    }
  ) {
    emitActiveConversation(data);
  },
};

export default actions;
