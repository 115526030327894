import { IAccount } from "@/domain/interfaces/account-interfaces";

export interface AccountState {
  loading: boolean;
  isSuccess: boolean;
  error: string;
  loginAccount: IAccount;
}

export const emptyAccount = <IAccount>{
  id: "",
  username: "",
  name: "",
  source: "",
  avatar: "",
  email: "",
  address: "",
  phone: "",
};

const state: AccountState = {
  loading: false,
  isSuccess: false,
  error: "",
  loginAccount: emptyAccount,
};

export default state;
