import configDevelopment from "./config.development";
import configLocal from "./config.local";
import configProduction from "./config.production";

const environment = process.env.VUE_APP_ENV;
let defaultConfig = configLocal;

if (environment === "development") 
  defaultConfig = configDevelopment;
 else if (environment === "production") 
  defaultConfig = configProduction;


export const FIREBASE_PROJECT = {
  config: {
    fuvior: {
      apiKey: "AIzaSyDDjtVL48of92iZBTzbiyXwVdIJJ9lXIyE",
      authDomain: "canteen-96f48.firebaseapp.com",
      databaseURL: "https://canteen-96f48.firebaseio.com",
      projectId: "canteen-96f48",
      storageBucket: "canteen-96f48.appspot.com",
      messagingSenderId: "27335103862",
      appId: "1:27335103862:web:02b97d7ef8f1f9999f1657",
      measurementId: "G-NKX85R8DP9",
    },
    ecard: {
      apiKey: "AIzaSyDDjtVL48of92iZBTzbiyXwVdIJJ9lXIyE",
      authDomain: "canteen-96f48.firebaseapp.com",
      databaseURL: "https://canteen-96f48.firebaseio.com",
      projectId: "canteen-96f48",
      storageBucket: "canteen-96f48.appspot.com",
      messagingSenderId: "27335103862",
      appId: "1:27335103862:web:02b97d7ef8f1f9999f1657",
      measurementId: "G-NKX85R8DP9",
    },
  },
  vapidKey: {
    fuvior:
      "BAex50qilDFd-OUe6NqgeyB9JP_O_Oj0vmydABVYmzw_xtVU0Acpz7sZxuUFdSTuRBA6jAreFBlrTKDr_zIrXls",
    ecard:
      "BAex50qilDFd-OUe6NqgeyB9JP_O_Oj0vmydABVYmzw_xtVU0Acpz7sZxuUFdSTuRBA6jAreFBlrTKDr_zIrXls",
  },
};

export const ENV = defaultConfig.env;

export const HOME_PAGE = defaultConfig.home;

export const WEB_HOST = defaultConfig.host;

export const API_HOST = defaultConfig.api.host;

export const API_USER_URL = `${defaultConfig.api.host}/app`;

export const UPLOAD_IMAGE_HOST = defaultConfig.api.imageHost;

export const DEFAULT_AVATAR_URL = `${defaultConfig.api.host}/images/default-avatar.png`;

export const WEB_HEADERS = {
  product: defaultConfig.api.webProduct,
  token: defaultConfig.api.webToken,
  authorization: "",
  deviceInfo: {},
};

export const ADMIN_HEADERS = {
  product: defaultConfig.api.adminProduct,
  token: defaultConfig.api.adminToken,
  authorization: "",
  deviceInfo: {},
};

export const COLOR = defaultConfig.color;
