import { IUser } from "@/domain/interfaces/user-interfaces";

export interface UserState {
  loading: boolean;
  isSuccess: boolean;
  error: string;
  loginUser: IUser;
  mode: string;

  friend: {
    fetching: boolean;
    moreFetching: boolean;
    items: Array<IUser>;
    groupByLetter: Array<{ letter: string; friendInLetter: Array<IUser> }>;
    total: number;
    page: number;
    limit: number;
  };
}

export const emptyUser = <IUser>{
  id: "",
  username: "",
  name: "",
  source: "",
  avatar: "",
  email: "",
  address: "",
  phone: "",
};

const state: UserState = {
  loading: false,
  isSuccess: false,
  error: "",
  loginUser: emptyUser,
  mode: "",
  friend: {
    items: [],
    total: 0,
    page: 1,
    limit: 10,
    fetching: false,
    groupByLetter: [],
    moreFetching: false,
  },
};

export default state;
