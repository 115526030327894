import { DMY_HM, dateToString, getStartDay } from "@/core/utils/date";
import { IUser } from "../interfaces/user-interfaces";
import _ from "lodash";
import { USER_CHAT_STATUS } from "../constant/user-constant";

export const customerGender = (gender?: string) => {
  if (!gender) return "";

  return gender === "0" ? "Female" : "Male";
};

export function getFirstLetterOfName (name?: string) {
  if (!name) return "#";
  const words = name.split(" ");

  if (words.length > 0) {
    const name = words[words.length - 1];

    return name.charAt(0);
  }

  return "#";
}

export function groupUserByLetter (users: Array<IUser>) {
  return _(users)
    .groupBy((user) => user.name.charAt(0))
    .map((friendInLetter, letter) => ({
      letter: letter || "#",
      friendInLetter,
    }))
    .valueOf();
}

export function renderChatStatusText (chatStatus?: {
  status: string;
  time: Date;
}) {
  if (!chatStatus) return "";

  if (chatStatus.status === USER_CHAT_STATUS.online) {
    return "Online";
  } else if (chatStatus.status === USER_CHAT_STATUS.offline) {
    const diffTime =
      getStartDay().getTime() -
      getStartDay(new Date(chatStatus?.time)).getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) 
      return "last seen within a day";
    
    return `last seen at ${dateToString(new Date(chatStatus?.time), DMY_HM)}`;
  }

  return "";
}
