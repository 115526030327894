export const API_SUCCESS = {
  status: 200,
  code: 1,
  message: "Thành công",
  data: {},
};

export const API_SESSION_TIMEOUT = {
  status: 0,
  code: -15,
  message: "Đăng nhập hết hạn",
  data: {},
};

export const API_SYS_ERROR = {
  status: 0,
  code: -1,
  message: "Hệ thống đang bận",
  data: {},
};

export const API_APP_ERROR = {
  status: 0,
  code: -1,
  message: "Có lỗi, vui lòng thử lại sau!",
  data: {},
};
