import { ModuleTree } from "vuex";
import { RootState } from "../state";
import accountModule from "./account";
import userModule from "./user";
import alertModule from "./alert";
import conversationModule from "./conversation";
import messageModule from "./message";

const modules: ModuleTree<RootState> = {
  alert: alertModule,
  account: accountModule,
  user: userModule,
  conversation: conversationModule,
  message: messageModule,
};

export default modules;
