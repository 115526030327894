import UserApi from "@/domain/api/user-api";

export default async (values: {
  product: string;
  token: string;
  cusid: string;
  accessToken: string;
  branchid: string;
  org: string;
  appversion: string;
}) => {
  values.org = values.org || "ND";
  values.product = values.product || "mobile-nd-app";
  values.token =
    values.token ||
    "ce8487e6cc90b234318fbdb70d2c4393a5bdbb711cf73b0eac4dfa63b0043958";
  console.log(values);

  if (
    !values.branchid ||
    !values.product ||
    !values.token ||
    !values.cusid ||
    !values.org
  )
    return {
      isSuccess: false,
      message: "Thông tin không hợp lệ",
      data: {
        token: undefined,
        user: undefined,
        activeConversation: undefined,
        mode: undefined,
      },
    };

  return await new UserApi().customerLogin({
    product: values.product,
    token: values.token,
    username: values.cusid,
    accessToken: values.accessToken,
    takeCareGroup: values.branchid,
    source: values.org,
    deviceInfo: { appVersion: values.appversion },
  });
};
