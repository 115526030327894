import { getFirebaseToken } from "@/core/utils/firebase-message";
import {
  getLoginAccount,
  getLoginUser,
  removeLoginAccount,
  removeLoginUser
} from "@/core/utils/session";
import router, { routesName } from "@/infrastructure/router";
import axios from "axios";
import fileDownload from "js-file-download";
import {
  API_APP_ERROR,
  API_SESSION_TIMEOUT,
  API_SYS_ERROR
} from "./error";

interface IApiResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

interface IHeader {
  product: string;
  token: string;
  authorization?: string;
  deviceInfo: any;
}

export default class ApiService {
  host: string;
  headers: IHeader;

  constructor (host: string, headers: IHeader) {
    this.host = host;
    this.headers = headers;
  }

  #sessionTimeout = () => {
    const isAdmin = this.headers.product === "admin";
    const userId = isAdmin ? getLoginAccount()?.id : getLoginUser()?.id;
    const path = isAdmin ? `account/${userId}` : `user/${userId}`;

    if (!userId) return;

    axios.put(
      `${this.host}/${path}/logout`,
      {},
      { headers: this.headers as any }
    );

    if (isAdmin) {
      removeLoginAccount();
      router.push(routesName.adminLogin);
    } else {
      removeLoginUser();
    }
  };

  async get (path: string, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers,
      });

      if (!response || !response.data) return API_SYS_ERROR as IApiResponse;

      const result = response.data as IApiResponse;

      if (result.code === API_SESSION_TIMEOUT.code) 
        this.#sessionTimeout();
      

      return result;
    } catch (error) {
      return API_APP_ERROR as IApiResponse;
    }
  }

  async post (path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      if (result.code === API_SESSION_TIMEOUT.code) 
        this.#sessionTimeout();
      

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }

  async put (path: string, body: object, query?: object) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers,
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      if (result.code === API_SESSION_TIMEOUT.code) 
        this.#sessionTimeout();
      

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }

  async remove (path: string) {
    try {
      const headers = {
        ...this.headers,
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
      };
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, { headers });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      if (result.code === API_SESSION_TIMEOUT.code) 
        this.#sessionTimeout();
      

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }

  async save (path: string, method = "POST", fileName: string) {
    const url = `${this.host}${path}`;
    const headers = { ...this.headers };

    return await axios({
      url,
      method,
      responseType: "blob", // Important
      headers,
    })
      .then((response) => {
        fileDownload(response.data, fileName);

        return { isSuccess: true };
      })
      .catch(() => {
        return { isSuccess: false };
      });
  }

  async upload (path: string, data: unknown) {
    try {
      const headers = {
        ...this.headers,
      };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, data, { headers });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      if (result.code === API_SESSION_TIMEOUT.code) 
        this.#sessionTimeout();
      

      return result;
    } catch (error) {
      return API_APP_ERROR;
    }
  }
}
