import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3cd81ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSpinnerPuff = _resolveComponent("VueSpinnerPuff")!
  const _component_VueSpinnerDots = _resolveComponent("VueSpinnerDots")!
  const _component_VueSpinnerTail = _resolveComponent("VueSpinnerTail")!
  const _component_VueSpinner = _resolveComponent("VueSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'puff')
      ? (_openBlock(), _createBlock(_component_VueSpinnerPuff, {
          key: 0,
          size: _ctx.size,
          color: _ctx.color.primary
        }, null, 8, ["size", "color"]))
      : (_ctx.type === 'dots')
        ? (_openBlock(), _createBlock(_component_VueSpinnerDots, {
            key: 1,
            size: _ctx.size,
            color: _ctx.color.primary
          }, null, 8, ["size", "color"]))
        : (_ctx.type === 'tail')
          ? (_openBlock(), _createBlock(_component_VueSpinnerTail, {
              key: 2,
              size: _ctx.size,
              color: _ctx.color.primary
            }, null, 8, ["size", "color"]))
          : (_openBlock(), _createBlock(_component_VueSpinner, {
              key: 3,
              size: _ctx.size,
              color: _ctx.color.primary
            }, null, 8, ["size", "color"]))
  ]))
}