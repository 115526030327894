export default {
  env: "development",
  home: "https://chat-dev.ngocdunggroup.com.vn",
  host: "https://chat-dev.ngocdunggroup.com.vn",
  api: {
    host: "https://mchat-socket-dev.ngocdunggroup.com.vn",
    imageHost: "//mchat-socket-dev.ngocdunggroup.com.vn/user/upload/avatar",
    adminProduct: "admin",
    adminToken:
      "3212dd79fb3a076e4676356a5feca1ddcb42a0da7d5918c0bacac45ce4021ce0",
    webProduct: "web",
    webToken:
      "8f43207b64a836944b50ed4a3e92094fde483733eff84fc84313579666b49d8e",
  },
  color: {
    primary: "#BC254A",
    secondary: "#5c738d",
    text: "#353535",
    text2: "#222222",
    border: "#B1B1B1",
  },
};
