
import { handleCopy } from "@/core/utils/common";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["copyText"],
  methods: {
    handleCopy,
  },
});
