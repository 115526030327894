export const GET_CONVERSATION_MESSAGE = "message/getMessages";

export const GET_MORE_CONVERSATION_MESSAGE = "message/getMoreMessages";

export const REMOVE_MESSAGE = "message/removeMessage";

export const SEND_MESSAGE = "message/sendMessage";

export const RECEIVE_NEW_MESSAGE = "message/receiveNewMessage";

export const UPDATE_SENT_MESSAGE = "message/updateSentMessage";

export const UPDATE_TOTAL_UNREAD_COUNT = "message/updateTotalUnreadCount";

export const SET_FORWARD_MESSAGE = "message/setForwardMessage";
