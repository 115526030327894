import { RootState } from "../../state/index";
import { ConversationState } from "./state";
import { GetterTree } from "vuex";

export const getters: GetterTree<ConversationState, RootState> = {
  isMore: (state) => {
    const count = state.data.items.length;
    const total = state.data.total;

    if (total - count > 0) return true;

    return false;
  },
  moreCount: (state) => {
    const count = state.data.items.length;
    const total = state.data.total;

    return total - count;
  },
  getForwardConversation: (state) => {
    return state.data.items.filter(
      (item) => item._id !== state.activeConversation?._id
    );
  },
};

export default getters;
