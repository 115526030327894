import { RootState } from "../../state/index";
import { MessageState } from "./state";
import { GetterTree } from "vuex";

export const getters: GetterTree<MessageState, RootState> = {
  isMore: (state) => {
    const count = state.data.items.length;
    const total = state.data.total;

    if (total - count > 0) return true;

    return false;
  },
};

export default getters;
