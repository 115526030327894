import { groupMessageByDate } from "@/domain/helpers/message-helpers";
import { IMessage } from "@/domain/interfaces/message-interfaces";
import { MutationTree } from "vuex";
import { MessageState } from "./state";

const mutations: MutationTree<MessageState> = {
  GET_MESSAGE_FETCHING (state) {
    state.fetching = true;
    state.data.items = [];
    state.data.groupByDate = [];
    state.data.total = 0;
    state.data.page = 1;
    state.scrollToMessage = "";
  },
  GET_MESSAGE_SUCCESS (
    state,
    data: { items: Array<IMessage>; total: number; limit: number }
  ) {
    state.scrollToMessage =
      data.items.length > 0 ? data.items[data.items.length - 1]._id : "";
    state.fetching = false;
    state.data.items = data.items;
    state.data.total = data.total;
    state.data.limit = data.limit;
    state.data.groupByDate = groupMessageByDate(data.items);
  },
  GET_MESSAGE_ERROR (state) {
    state.fetching = false;
    state.scrollToMessage = "";
    state.data.items = [];
    state.data.groupByDate = [];
    state.data.total = 0;
  },
  GET_MORE_MESSAGE_FETCHING (state) {
    state.moreFetching = true;
  },
  GET_MORE_MESSAGE_SUCCESS (
    state,
    data: { items: Array<IMessage>; total: number; limit: number }
  ) {
    state.moreFetching = false;
    state.data.total = data.total;
    state.data.limit = data.limit;
    state.data.items = data.items.concat(state.data.items);
    state.data.groupByDate = groupMessageByDate(state.data.items);
    state.data.page = state.data.page + 1;
  },
  GET_MORE_MESSAGE_ERROR (state) {
    state.moreFetching = false;
  },
  REMOVE_MESSAGE_SUCCESS (state, id: string) {
    const index = state.data.items.findIndex((item) => item._id === id);
    state.data.items.splice(index, 1);
    state.data.groupByDate = groupMessageByDate(state.data.items);
    state.data.total--;
  },
  UPDATE_SENT_MESSAGE_SUCCESS (state, message: IMessage) {
    const index = state.data.items.findIndex(
      (item) => item._id === new Date(message.senderSentAt).getTime().toString()
    );

    if (index !== -1) {
      state.data.items[index] = message;
      state.data.groupByDate = groupMessageByDate(state.data.items);
    }
  },
  UPDATE_ACTIVE_CONVERSATION_NEW_MESSAGE_SUCCESS (state, message: IMessage) {
    state.scrollToMessage = message._id;
    state.data.items.push(message);
    state.data.groupByDate = groupMessageByDate(state.data.items);
    state.data.total++;
  },
  UPDATE_TOTAL_UNREAD_COUNT_SUCCESS (state, count: number) {
    state.totalUnreadCount = count;
  },
  SET_FORWARD_MESSAGE_SUCCESS (state, message: IMessage) {
    state.forwardMessage = message;
  },
};

export default mutations;
