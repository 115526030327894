import UserApi from "@/domain/api/user-api";

export default async (values: {
  product: string;
  token: string;
  username: string;
  accessToken: string;
  conversationId?: string;
}) => {
  if (!values.product || !values.token || !values.username)
    return {
      isSuccess: false,
      message: "Thông tin không hợp lệ",
      data: {
        token: undefined,
        user: undefined,
        mode: undefined,
        activeConversation: undefined,
      },
    };

  return await new UserApi().userLogin({
    product: values.product,
    token: values.token,
    username: values.username,
    accessToken: values.accessToken,
    conversationId: values.conversationId,
  });
};
