import changePasswordUseCase from "@/domain/usecases/account/change-password";
import forgotPasswordUseCase from "@/domain/usecases/account/forgot-password";
import loginUseCase from "@/domain/usecases/account/login";
import logoutUseCase from "@/domain/usecases/account/logout";
import resetPasswordUseCase from "@/domain/usecases/account/reset-password";
import updateDeviceInfoUseCase from "@/domain/usecases/account/update-device-info";

import { ActionTree } from "vuex";
import { RootState } from "@/infrastructure/store/state";
import { AccountState } from "./state";
import { getLoginAccount } from "@/core/utils/session";
import router, { routesName } from "@/infrastructure/router";
import {
  ALERT_ERROR,
  ALERT_SUCCESS
} from "@/domain/constant/store/actions/alert-actions";

export const actions: ActionTree<AccountState, RootState> = {
  async setCurrentLoginAccount ({ commit, state }) {
    const loginAccount = getLoginAccount();

    if (loginAccount && loginAccount.id !== "") {
      updateDeviceInfoUseCase(loginAccount.id);
      commit("SET_CURRENT_LOGIN_ACCOUNT", loginAccount);

      return state.loginAccount;
    }
  },
  async login ({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await loginUseCase(data);

    if (result.isSuccess) {
      commit("ACCOUNT_LOGIN_SUCCESS", result.data);

      return router.replace(routesName.home);
    }
    commit("ACCOUNT_LOGIN_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async logout ({ commit, state }) {
    logoutUseCase(state.loginAccount?.id);
    commit("ACCOUNT_LOGOUT_SUCCESS");
    router.push(routesName.adminLogin);
  },
  async changePassword ({ commit, dispatch, state }, values) {
    commit("SET_LOADING");
    const result = await changePasswordUseCase(state.loginAccount.id, values);

    if (result.isSuccess) {
      commit("CHANGE_PASSWORD_SUCCESS");

      return dispatch(ALERT_SUCCESS, result.message, { root: true });
    }

    commit("CHANGE_PASSWORD_ERROR", result.message);

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async resetPassword ({ commit, dispatch }, values) {
    commit("SET_LOADING");
    const result = await resetPasswordUseCase(values);

    if (result.isSuccess) {
      commit("RESET_PASSWORD_SUCCESS");
      router.replace(routesName.adminLogin);

      return;
    }
    commit("RESET_PASSWORD_ERROR", result.message);
    dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async forgotPassword ({ commit, dispatch }, email) {
    commit("SET_LOADING");
    const result = await forgotPasswordUseCase(email);

    if (result.isSuccess) 
      return commit("FORGOT_PASSWORD_SUCCESS", result.isSuccess);
    
    commit("FORGOT_PASSWORD_ERROR", result.isSuccess);

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
};

export default actions;
