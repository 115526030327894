import {
  IAccount,
  IAccountLogin
} from "@/domain/interfaces/account-interfaces";
import { AccountState, emptyAccount } from "./state";
import { MutationTree } from "vuex";
import { removeLoginAccount, setLoginAccount } from "@/core/utils/session";

const mutations: MutationTree<AccountState> = {
  SET_LOADING (state) {
    state.loading = true;
    state.isSuccess = false;
  },
  SET_CURRENT_LOGIN_ACCOUNT (state, loginAccount: IAccount) {
    state.loginAccount = loginAccount;
  },

  ACCOUNT_LOGIN_SUCCESS (state, data: IAccountLogin) {
    setLoginAccount(data);
    state.loginAccount = data.user;
    state.loading = false;
  },
  ACCOUNT_LOGIN_ERROR (state) {
    state.loading = false;
    state.isSuccess = false;
    state.loginAccount = emptyAccount;
  },
  ACCOUNT_LOGOUT_SUCCESS (state) {
    state.loginAccount = emptyAccount;
    removeLoginAccount();
  },
  REGISTER_ACCOUNT_SUCCESS (state) {
    state.isSuccess = true;
    state.loading = false;
    state.error = "";
  },
  REGISTER_ACCOUNT_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  RESET_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  RESET_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  CHANGE_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  CHANGE_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  FORGOT_PASSWORD_SUCCESS (state) {
    state.error = "";
    state.isSuccess = true;
    state.loading = false;
  },
  FORGOT_PASSWORD_ERROR (state, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
};

export default mutations;
