import customerCareLoginUseCase from "@/domain/usecases/user/customer-care-login";
import customerLoginUseCase from "@/domain/usecases/user/customer-login";
import getUserFriendUseCase from "@/domain/usecases/user/get-friend-list";
import getUseDetailUseCase from "@/domain/usecases/user/get-user-detail";
import userLoginUseCase from "@/domain/usecases/user/user-login";

import { getLoginUser } from "@/core/utils/session";
import { ALERT_ERROR } from "@/domain/constant/store/actions/alert-actions";
import { SET_ACTIVE_CONVERSATION } from "@/domain/constant/store/actions/conversation-actions";
import { USER_MODE } from "@/domain/constant/user-constant";
import router, { routesName } from "@/infrastructure/router";
import socketConnect from "@/infrastructure/socket";
import { RootState } from "@/infrastructure/store/state";
import { ActionTree } from "vuex";
import { UserState } from "./state";

export const actions: ActionTree<UserState, RootState> = {
  async setCurrentLoginUser ({ commit, state }) {
    const loginUser = getLoginUser();

    if (loginUser && loginUser.id !== "") {
      commit("SET_CURRENT_LOGIN_USER", loginUser);

      return state.loginUser;
    }
  },
  async customerCareUnreadCount (
    { dispatch },
    data: {
      product: string;
      token: string;
      org: string;
      branchid: string;
      phone: string;
    }
  ) {
    const result = await getUseDetailUseCase({
      username: data.phone,
      takeCareGroup: `${data.org}|${data.branchid}`,
    });

    if (result.isSuccess)
      return socketConnect({
        takeCareGroup: result.data.takeCareGroup.id,
        userId: result.data._id,
        mode: USER_MODE.customerCare,
      });

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async customerCareLogin ({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await customerCareLoginUseCase(data);

    if (result.isSuccess) {
      commit("USER_LOGIN_SUCCESS", result.data);

      dispatch(
        SET_ACTIVE_CONVERSATION,
        { conversation: result.data.activeConversation },
        {
          root: true,
        }
      );

      return router.replace({ name: routesName.home });
    }

    return commit("USER_LOGIN_ERROR", result.message);
  },
  async customerLogin ({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await customerLoginUseCase(data);

    if (result.isSuccess) {
      commit("USER_LOGIN_SUCCESS", result.data);

      dispatch(
        SET_ACTIVE_CONVERSATION,
        { conversation: result.data.activeConversation },
        {
          root: true,
        }
      );

      return router.replace({ name: routesName.home });
    }

    return commit("USER_LOGIN_ERROR", result.message);
  },
  async userLogin ({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await userLoginUseCase(data);

    if (result.isSuccess) {
      commit("USER_LOGIN_SUCCESS", result.data);

      dispatch(
        SET_ACTIVE_CONVERSATION,
        { conversation: result.data.activeConversation },
        {
          root: true,
        }
      );

      return router.replace({ name: routesName.home });
    }
    commit("USER_LOGIN_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async getFriends ({ commit, dispatch, state }, data) {
    if (state.friend.fetching) return;

    commit("GET_FRIEND_FETCHING", true);
    const result = await getUserFriendUseCase({
      page: 1,
      search: data.search,
      conversation: data.conversation,
    });

    if (result.isSuccess) return commit("GET_FRIEND_SUCCESS", result.data);

    commit("GET_FRIEND_FETCHING", false);

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async getMoreFriends ({ commit, dispatch, state }, data) {
    if (state.friend.moreFetching) return;

    commit("GET_MORE_FRIEND_FETCHING", true);
    const result = await getUserFriendUseCase({
      page: state.friend.page + 1,
      search: data.search,
    });

    if (result.isSuccess) return commit("GET_MORE_FRIEND_SUCCESS", result.data);

    commit("GET_MORE_FRIEND_FETCHING", false);

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async setFriendChatStatus ({ commit }, data) {
    commit("SET_FRIEND_CHAT_STATUS", data);
  },
};

export default actions;
