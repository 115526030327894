import uploadFileUseCase from "@/domain/usecases/file/upload-file";
import getConversationMessageUseCase from "@/domain/usecases/message/get-conversation-message";
import removeMessageUseCase from "@/domain/usecases/message/remove-message";

import { MESSAGE_STATUS } from "@/domain/constant/message-constant";
import { ALERT_ERROR } from "@/domain/constant/store/actions/alert-actions";
import { UPDATE_CONVERSATION } from "@/domain/constant/store/actions/conversation-actions";
import { USER_MODE } from "@/domain/constant/user-constant";
import { IMessage } from "@/domain/interfaces/message-interfaces";
import { emitSendMessage } from "@/infrastructure/socket";
import { RootState } from "@/infrastructure/store/state";
import { ActionTree } from "vuex";
import { MessageState } from "./state";

interface SendMessagePayLoad {
  content: string;
  type: string;
  forward?: string;
  file?: File;
  link?: string; //link of image|video|audio type
}
interface UpdateMessagePayLoad {
  id: string;
  updateData: { status: string; id: string };
}

export const actions: ActionTree<MessageState, RootState> = {
  async getMessages ({ commit, dispatch }, values: { id: string }) {
    commit("GET_MESSAGE_FETCHING");
    const result = await getConversationMessageUseCase(values.id, { page: 1 });

    if (result.isSuccess) 
      return commit("GET_MESSAGE_SUCCESS", result.data);
    
    commit("GET_MESSAGE_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async getMoreMessages ({ commit, dispatch, state }, values: { id: string }) {
    if (state.fetching) return;

    const result = await getConversationMessageUseCase(values.id, {
      page: state.data.page + 1,
    });

    if (result.isSuccess) 
      return commit("GET_MORE_MESSAGE_SUCCESS", result.data);
    
    commit("GET_MORE_MESSAGE_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async removeMessage ({ commit, dispatch }, message: IMessage) {
    if (message.status === MESSAGE_STATUS.sending) 
      return commit("REMOVE_MESSAGE_SUCCESS", message._id);
    
    const result = await removeMessageUseCase(message._id);

    if (result.isSuccess) {
      commit("REMOVE_MESSAGE_SUCCESS", message._id);

      return dispatch(UPDATE_CONVERSATION, result.data, {
        root: true,
      });
    }

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async updateSentMessage ({ commit }, payload: UpdateMessagePayLoad) {
    commit("UPDATE_SENT_MESSAGE_SUCCESS", payload);
  },
  async sendMessage (
    { rootState, commit, dispatch },
    payload: SendMessagePayLoad
  ) {
    const { activeConversation } = rootState.conversation;
    const { loginUser, mode } = rootState.user;

    if (!activeConversation || !loginUser) return;

    const senderSentAt = new Date();
    const message: IMessage = {
      _id: senderSentAt.getTime().toString(),
      conversation: activeConversation._id,
      sender: {
        _id: loginUser.id,
        name: loginUser.name,
        avatar: loginUser.avatar,
        username: loginUser.username,
      },
      content: payload.content,
      type: payload.type,
      status: MESSAGE_STATUS.sending,
      isBot: false,
      sentAt: senderSentAt,
      senderSentAt,
      takeCareGroup:
        mode === USER_MODE.customerCare ? activeConversation.takeCareGroup : "",
      forward: payload.forward,
      link: payload.link,
    };

    commit("UPDATE_ACTIVE_CONVERSATION_NEW_MESSAGE_SUCCESS", message);

    let content = payload.content;

    if (payload.file) {
      const result = await uploadFileUseCase(payload.file);

      if (!result.isSuccess) 
        return dispatch(ALERT_ERROR, "Không thể gửi file", { root: true });
      
      content = result.data.id;
    }

    emitSendMessage(mode, {
      content,
      type: payload.type,
      senderSentAt,
      forward: payload.forward,
    });
  },
  async receiveNewMessage ({ commit, rootState }, message: IMessage) {
    const { activeConversation } = rootState.conversation;

    if (activeConversation?._id === message.conversation) 
      commit("UPDATE_ACTIVE_CONVERSATION_NEW_MESSAGE_SUCCESS", message);
    
  },
  async updateTotalUnreadCount ({ commit }, count: number) {
    commit("UPDATE_TOTAL_UNREAD_COUNT_SUCCESS", count);
  },
  async setForwardMessage ({ commit }, message: IMessage) {
    commit("SET_FORWARD_MESSAGE_SUCCESS", message);
  },
};

export default actions;
