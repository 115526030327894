export const SOURCE = {
  ND: "ND",
  ZEMA: "ZEMA",
};

export const USER_MODE = {
  customer: "customer",
  customerCare: "customerCare",
  user: "user",
};

export const USER_CHAT_STATUS = {
  online: "online",
  offline: "offline",
};
