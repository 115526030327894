import { RootState } from "./../../state/index";
import { AccountState } from "./state";
import { GetterTree } from "vuex";

export const getters: GetterTree<AccountState, RootState> = {
  getLoginAccount: (state: AccountState) => {
    return state.loginAccount;
  },
};

export default getters;
