import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export const routesName = {
  adminLogin: "adminLogin",
  home: "home",
  login: "login",
  messages: "messages",
  customer: "customer",
  user: "user",
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.home,
    component: () => import("../../interfaces/views/ChatView.vue"),
  },
  {
    path: "/login",
    name: routesName.login,
    meta: { authRequired: false },
    component: () => import("../../interfaces/views/LoginView.vue"),
  },
  {
    path: "/messages",
    name: routesName.messages,
    component: () => import("../../interfaces/views/CustomerCareLoginView.vue"),
  },
  {
    path: "/customer",
    name: routesName.customer,
    component: () => import("../../interfaces/views/CustomerLoginView.vue"),
  },
  {
    path: "/user",
    name: routesName.user,
    component: () => import("../../interfaces/views/UserLoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../../interfaces/views/NotFoundView.vue"),
  },
  {
    path: "/message-count",
    component: () => import("../../interfaces/views/MessageCount.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
