import ConversationApi from "@/domain/api/conversation-api";

export default async (values: {
  id: string;
  mode: string;
  page: number;
  search?: string;
}) => {
  return await new ConversationApi().getConversationList(values);
};
