import { createApp } from "vue";
import App from "./App.vue";
import AppEmpty from "./interfaces/components/common/AppEmpty.vue";
import AppAlert from "./interfaces/components/common/AppAlert.vue";
import AppLoading from "./interfaces/components/common/AppLoading.vue";
import CopyIcon from "./interfaces/components/common/CopyIcon.vue";

import router from "@/infrastructure/router";
import store, { key } from "@/infrastructure/store";
import i18n from "@/infrastructure/i18n";

createApp(App)
  .use(store, key)
  .use(router)
  .use(i18n)
  .component("AppEmpty", AppEmpty)
  .component("AppAlert", AppAlert)
  .component("AppLoading", AppLoading)
  .component("CopyIcon", CopyIcon)

  .mount("#app");
